<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <hr>
      <h3>Возникли вопросы?</h3>
      <p>Мы работаем <b>{{ supportWorkingHours }}</b></p>
      <p>Служба заботы о клиентах: <b><a :href="'tel:' + supportPhone">{{ supportPhone }}</a></b><br>
      Email: <b><a :href="'mailto:' + supportEmail">{{ supportEmail }}</a></b></p>
    </div>
  </main>
</template>=

<script>
import { mapGetters } from 'vuex'
import { supportPhones, supportEmails, workingHours } from 'base/modules/constants'

export default {
  name: 'Contacts',
  computed: {
    ...mapGetters({
      region: 'main/region',
    }),
    supportPhone () {
      return supportPhones[this.region]
    },
    supportEmail () {
      return supportEmails[this.region]
    },
    supportWorkingHours () {
      return workingHours[this.region]
    }
  },
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
